import React from 'react'

function NotFound() {
    return(
        <div style={{"background-color": "purple"}}>
            notFound
        </div>
    )
}

export default NotFound